@config "../tailwind.config.cjs";
@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import '~bootstrap/dist/css/bootstrap.min.css'; */

@layer utilities {
    /* Scroll thumb styles */
    .scrollbar::-webkit-scrollbar {
        @apply w-1.5 !important;
    }

    .scrollbar::-webkit-scrollbar-thumb {
        @apply rounded-md bg-gray-300 !important;
    }

    .scrollbar::-webkit-scrollbar-track {
        @apply bg-gray-100 !important;
    }

    .smoothscroll {
        background-color: rgba(0, 0, 0, 0);
        -webkit-background-clip: text;
        /* uncomment following line to see the trick in actions */
        /* -webkit-text-fill-color: transparent; */
        transition: background-color 0.8s;
        scrollbar-gutter: stable;
    }

    .smoothscroll:hover {
        background-color: rgba(0, 0, 0, 0.18);
    }

    .smoothscroll::-webkit-scrollbar {
        @apply w-1.5 !important;
        @apply h-3.5;
    }

    .smoothscroll::-webkit-scrollbar-track {
        @apply hidden;
    }

    .smoothscroll::-webkit-scrollbar-thumb {
        @apply rounded-lg;
        @apply h-3.5 !important;
        background-color: inherit;
    }

    .sidebarBg {
        background: linear-gradient(180deg, #4968d3 0%, #0e0c10 100%);
    }

    .bgmain-gray {
        background: #e5e5e5;
    }

    .scrollbroad {
        background-color: rgba(0, 0, 0, 0);
        -webkit-background-clip: text;
        /* uncomment following line to see the trick in actions */
        /* -webkit-text-fill-color: transparent; */
        transition: background-color 0.8s;
        scrollbar-gutter: stable;
        margin: 5px;
        padding: 10px;
    }

    .scrollbroad::-webkit-scrollbar {
        width: 14px;
        height: 14px;
        background-color: transparent;
        -webkit-background-clip: text;
    }

    .scrollbroad::-webkit-scrollbar-track {
        background: #c4c4c4;
        width: 2px;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-top: 6px solid transparent;
        -webkit-background-clip: padding-box;
    }

    .scrollbroad::-webkit-scrollbar-thumb {
        background: #d3dbe8;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .scrollshort::-webkit-scrollbar-button:end:increment {
        height: 40%;
        display: block;
        background: transparent;
    }

    .score-jobtitle {
        color: #006be6;
    }

    .hoverblue {
        background: #1e41a3;
    }

    #sidebar-nav > .active > a {
        background: #1e41a3;
    }

    html {
        overflow: hidden;
        font-size: 16px;
        font-family: var(--font-karla), opens-sans, sans-serif;
    }
}

a {
    color: #1675e0;
}

/* rsuite */
/* @import './rsuite-compiled.css'; */
/* rsuite */

.blur-text {
    /* text-shadow: 0 0 20px #9491aa;
    color: #f1effe; */
    filter: blur(6px);
}

kbd {
    background-color: transparent !important;
}

.rs-calendar-body {
    padding: 0 !important;
}

.rs-calendar-panel.rs-calendar-compact .rs-calendar-table-row:not(.rs-calendar-table-header-row) .rs-calendar-table-cell-content {
    height: auto !important;
}

.rs-calendar {
    padding-top: 0 !important;
}

.szh-menu__item--type-checkbox::before {
    display: none !important;
}

center {
    display: flex;
    justify-content: center;
    align-items: center;
}

#__next {
    height: 100vh;
}

input {
    border-color: #dbdde0;
}

textarea {
    border-color: #dbdde0;
}

/* JOB CREATION PAGE  */
.job-title-input {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 600 !important;
    border-radius: 0%;
    border: 0;
    padding: 0;
    padding-bottom: 4px;
    z-index: 1;
    box-shadow: none;
    background-color: transparent;
    border-bottom: 1.5px solid lightgray;
    font: inherit;
    font-size: 1.5rem;
    line-height: 30px;
}
.assessment-subtitle-input {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 400 !important;
    border-radius: 0%;
    border: 0;
    padding: 4px;
    padding-bottom: 4px;
    z-index: 1;
    box-shadow: none !important;
    background-color: transparent;
    font: inherit;
    font-size: 0.875rem;
    line-height: 30px;
}

.note-details ul {
    margin-left: -20px !important;
}

/* JOB CREATION PAGE  */
.assessment-question-input {
    color: #2e354a;
    font-family: var(--font-montserrat);
    font-size: 1.75rem;
    font-weight: 600 !important;
    border-radius: 0%;
    border: 0;
    padding: 0;
    z-index: 1;
    box-shadow: none;
    background-color: transparent;
    line-height: 30px;
}

.assessment-question-input:focus {
    outline: none !important;
}

.assessment-question-input::placeholder {
    color: #9498a2;
    font-family: var(--font-montserrat);
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2rem;
    /* 114.286% */
}

/* SWITCH RADIO BUTTON */
:where(.css-dev-only-do-not-override-yp8pcc).ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background: #425ebd !important;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-switch.ant-switch-checked {
    background: #425ebd;
}

.compensation-dropdown :where(.css-dev-only-do-not-override-yp8pcc).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100% !important;
    height: 37px !important;
    padding: 2px 11px !important;
}

/* 
.ant-select-selector {
    width: 100% !important;
    height: 37px !important;
    padding: 2px 11px !important;
} */

.job-interviewer-list .ant-select-selector {
    height: max-content !important;
}

.job-interviewer-list .ant-select-selection-overflow {
    height: max-content;
}

.job-question-list .ant-select-selector {
    height: max-content !important;
}

.job-question-list .ant-select-selection-overflow {
    height: max-content;
}

.ant-rate-star {
    color: #f1f5ff !important;
    /* Change 'red' to the desired color */
}

.ant-rate-star:hover {
    color: #2f4386 !important;
    /* Change 'yellow' to the desired hover color */
}

.ant-rate-star-full,
.ant-rate-star-half {
    color: #2f4386 !important;
    /* Change 'green' to the desired color for filled stars */
}

.profile .ant-rate-star-second {
    color: #f1f5ff;
}

.rogue-rating .ant-rate-star:hover {
    color: #be604c !important;
    /* Change 'yellow' to the desired hover color */
}

.rogue-rating .ant-rate-star-full,
.rogue-rating .ant-rate-star-half {
    color: #be604c !important;
    /* Change 'green' to the desired color for filled stars */
}

.profile .ant-rate-star-second {
    color: #f1f5ff;
}

.scroll-container::after {
    content: '';
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    pointer-events: none;
    width: 1%;
}

.scroll-container::before {
    content: '';
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;

    pointer-events: none;
    width: 1%;
}

.sun-editor-editable p {
    font-size: 0.875rem !important;
    font-family: var(--font-karla) !important;
}

.shadow-login-container {
    box-shadow: 0px 1px 2px 0px rgba(18, 20, 22, 0.05);
}

.login-main {
    background: linear-gradient(180deg, #f2f5ff 0%, #fdfdfd 100%, #fff 100%);
}

.login-container {
    background: linear-gradient(180deg, #e6ebfa 0%, rgba(230, 250, 247, 0) 100%);
}

body {
    height: 100vh !important;
    width: 100%;
}

* {
    margin: 0;
    padding: 0;
    font-family: var(--font-karla);
    font-size: 16px;
}

.blue .side-nav {
    background: linear-gradient(180deg, #3e58b3 0%, #2a3b78 100%) !important;
}

.info .side-nav {
    background: linear-gradient(180deg, #04a2cd 0%, #02627c 100%);
    box-shadow:
        0px 1px 2px 0px rgba(18, 20, 22, 0.06),
        0px 1px 3px 0px rgba(18, 20, 22, 0.1);
}

.product-moss .side-nav {
    background: linear-gradient(180deg, #5d9197 0%, #3e6165 100%) !important;
}

.green .side-nav {
    background: linear-gradient(180deg, #519a42 0%, #36672c 100%) !important;
}

.purple .side-nav {
    background: linear-gradient(180deg, #885aad 0%, #5b3c74 100%) !important;
}

.moss-green .side-nav {
    background: #048e79;
}

.moss .side-nav {
    background: linear-gradient(180deg, #5d9197 0%, #3e6165 100%) !important;
}

.yellow .side-nav {
    background: linear-gradient(180deg, #d7b22c 0%, #90771e 100%) !important;
}

.info .side-nav {
    background: linear-gradient(180deg, #04a2cd 0%, #02627c 100%) !important;
}

.orange .side-nav {
    background: linear-gradient(180deg, #c87c37 0%, #865325 100%) !important;
}

.rouge .side-nav {
    background: linear-gradient(180deg, #cc5945 0%, #893c2f 100%) !important;
}

.mauve .side-nav {
    background: linear-gradient(180deg, #be604c 0%, #8b3751 0.01%, #5d2536 100%) !important;
}

.pink .side-nav {
    background: linear-gradient(180deg, #b35eb4 0%, #904c91 100%) !important;
}

.gray .side-nav {
    background: linear-gradient(180deg, #757575 0%, #323232 100%) !important;
}

.egg-plant .side-nav {
    background: linear-gradient(180deg, #60568f 0%, #3c355e 100%) !important;
}

.strawberry .side-nav {
    background: linear-gradient(180deg, #FF6DA8 0%, #804059 100%) !important;

}

:root {
    --primary-50: rgb(237, 240, 251) /*#edf0fb*/;
    --primary-100: rgb(211, 219, 244) /*#d3dbf4*/;
    --primary-200: rgb(177, 190, 236) /*#b1beec*/;
    --primary-300: rgb(140, 160, 227) /*#8ca0e3*/;
    --primary-400: rgb(106, 131, 219) /*#6a83db*/;
    --primary-500: rgb(73, 104, 211) /*#4968d3*/;
    --primary-600: rgb(62, 88, 179) /*#3e58b3*/;
    --primary-700: rgb(52, 74, 150) /*#344a96*/;
    --primary-800: rgb(42, 59, 120) /*#2a3b78*/;
    --primary-900: rgb(33, 47, 95) /*#212f5f*/;
}

:root .side-nav {
    background: linear-gradient(180deg, #3e58b3 0%, #2a3b78 100%);
}

/* * {
    --antd-arrow-background-color: #e6f7fc !important;
}

.ant-popover-inner {
    background: #e6f7fc !important;
} */

.ant-switch {
    background: rgba(0, 0, 0, 0.45) !important;
}

.ant-switch-checked {
    background: #425ebd !important;
}

.ant-input-number {
    padding: 0 !important;
}

@keyframes fadeInUp {
    0% {
        opacity: 0.8;
        scale: 0.95;
    }
    100% {
        opacity: 1;
        scale: 1;
        transform: translateY(4px);
    }
}

.card-up {
    animation: fadeInUp 400ms linear;
}
.moveable-line {
    position: absolute;
    width: 1px;
    height: 1px;
    background: red !important;
    transform-origin: 0px 0.5px;
    visibility: hidden !important;
}
.moveable-control {
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 2px solid #fff;
    box-sizing: border-box;
    background: black !important;
    margin-top: -7px;
    margin-left: -7px;
    z-index: 10;
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

.animated-card {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}

@keyframes moving {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -200% 0;
    }
}

.loading-bar {
    height: 1.25rem;
    transform-origin: left; /* Without this, you'll notice the `expanding` animation starts expanding from the  middle instead of shooting out from the left */
    animation:
        expanding 0.4s 0.7s forwards linear,
        moving 1s 1s infinite forwards linear;
    border-radius: 0.125rem;
    background-image: linear-gradient(to right, #eff6ff 30%, #2563eb60 60%, #eff6ff);
    background-size: 200% auto;
    opacity: 0; /* The `expanding` animation will fade in the loading bar */
    animation-delay: 100ms;
}

/* antd Date Range Picker and Select font matched to local components */
.ant-select-selection-placeholder {
    @apply text-neutralgray-6 !important; /* Tailwind utility applied */
    font-size: 0.875rem !important;
}

.ant-select-selection-search input::placeholder {
    @apply text-neutralgray-6 !important; /* Tailwind utility applied */
    @apply text-sm !important; /*Tailwind utility applied*/
    @apply leading-none !important; /*Tailwind utility applied*/
}

.ant-picker-input input::placeholder {
    @apply text-neutralgray-6 !important; /* Tailwind utility applied */
    @apply text-sm;
}

.gradient-background {
    background: linear-gradient(349deg, rgb(160 106 203 / 0.6), rgb(73 104 211 / 0.6), rgb(20 184 166 / 0.6));
    background-size: 300% 300%;
    animation: gradient-animation 1s ease infinite;
}

@keyframes gradient-animation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
